<template>
  <div class="text-right">
    <v-dialog
      v-model="openDialogEdit"
      width="700px"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
    >
      <v-card>
        <v-card-title class="text-h5">
          แก้ไข Infographic
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผลงาน (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อผลงาน (TH)"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผลงาน (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อผลงาน (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้จัดทำ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.creator_th"
                  placeholder="ระบุชื่อผู้จัดทำ (TH)"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้จัดทำ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.creator_en"
                  placeholder="ระบุชื่อผู้จัดทำ (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">สาขา</span>
                <v-autocomplete
                  hide-details
                  v-model="form.subject_th"
                  :items="itemsCourse"
                  item-value="id"
                  item-text="title_th"
                  placeholder="เลือกสาขา"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6"> </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่ออาจารย์ที่ปรึกษา (TH)</span>
                <v-text-field
                  hide-details
                  v-model="form.teacher_th"
                  placeholder="ระบุชื่ออาจารย์ที่ปรึกษา (TH)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่ออาจารย์ที่ปรึกษา (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.teacher_en"
                  placeholder="ระบุชื่ออาจารย์ที่ปรึกษา (EN)"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12" md="12" sm="12" class="ml-n3"
              ><span class="pt-2">รูปผลงาน</span>
              <span style="color: red">*</span>
            </v-col>

            <v-row>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                <v-img
                  :src="showImage"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                <v-img
                  src="../../assets/noFile.png"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <span>{{ namePic }}</span>
                <br />
                <span>เลือกรูปภาพภาพบรรยากาศ</span>
                <br />
                <br />
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="file"
                  ref="q_1_1"
                  v-on:change="submitfileMain('q_1_1')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: {
    openDialogEdit: Boolean,
    itemEdit: Object,
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      namePic: "",
      dialog: false,

      form: {
        name_th: "",
        name_en: "",
        creator_th: "",
        creator_en: "",
        teacher_th: "",
        teacher_en: "",
        subject_th: "",
        image_url_en: "",
      },
      title_en: null,
      title_th: null,
      desc_en: null,
      desc_th: null,
      image_url_en: null,
      // bannerDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],

      file: [],

      itemsCourse: [],
    };
  },
  watch: {
    openDialogEdit(visible) {
      if (visible) {
        this.form = this.itemEdit;
        this.showImage = this.itemEdit.image_url_en;
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.getAllCourse();
  },
  methods: {
    async getAllCourse() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/courses`,
        auth
      );
      this.itemsCourse = response.data.data;
      this.loading = false;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.form.image_url_en = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        console.log("pic", pic);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/studentWorks/${this.itemEdit.id}`,
          data,
          auth
        );
        console.log("response", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAll");
          this.cancel();
          // this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    cancel() {
      this.$refs.createForm.reset();
      this.$refs.q_1_1.value = "";
      this.showImage = "";
      this.$emit("closeDialogEdit");
    },
  },
};
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>