<template>
  <div>
    <ManageStudentWorks />
  </div>
</template>
<script>
import ManageStudentWorks from "@/components/ManageStudentWorks/ManageStudentWorks";
export default {
  components: {
    ManageStudentWorks,
  },
  created() {},
};
</script>
